import { ParticipateBoxProps } from "./ParticipateFormBox";

export const INVOLVEDCONFIG: ParticipateBoxProps[] = [
  {
    name: "Artists",
    bodyText:
      "Sign up below if you would like to sell your work at WreckCon 2025, but you do not have a business license. Tabling space is totally free, but unfortunately not guaranteed.",
    link: "https://forms.gle/kvhUyLAfUJo2WA998",
    buttonText: "Artist Intake",
    color: "#659470",
    isOpen: false
  },
  {
    name: "Vendors",
    bodyText:
      "If you are a seller interested in WreckCon 2025 with a business license, please fill out the form below! All other sellers should use the Artists signup form.",
    link: "https://forms.gle/xTpZRyW7oiebYn3n6",
    buttonText: "Vendor Intake",
    color: "#FF7F41",
        isOpen: false

  },
  {
    name: "RSO Intake",
    bodyText:
      "If you are interested in applying for membership in the WreckCon RSO, please fill out the form below!",
    link: "https://docs.google.com/forms/d/e/1FAIpQLScfVxvDfGG828S1f7wFYvsRQf1TXACiIqHUTBpIduh8U3bFww/viewform?usp=sharing",
    buttonText: "RSO Intake",
    color: "#FFC42D",
        isOpen: true

  },
  {
    name: "Volunteers",
    bodyText:
      "We're always looking for help managing WreckCon. If you would like to help out and volunteer on the day of the of the convention, you can sign up here!",
    link: "https://forms.gle/2nJDnXiTGpWZPGFY9",
    buttonText: "Volunteer Intake",
    color: "#64A2FF",
        isOpen: false

  },
  {
    name: "Panelists",
    bodyText:
      "Have an idea or topic you're dying to share at WreckCon? Paneling space is completely free, but highly in-demand, so sign up as soon as you can!",
    link: "https://forms.gle/rKWmuNAxvnnStNqy8",
    buttonText: "Panelist Intake",
    color: "#A880FF",
        isOpen: false

  },
  {
    name: "Indie Games",
    bodyText:
      "If you would like to demo a video game or other itneractive media at WreckCon, sign up below! Tabling space will be free, but is not guaranteed.",
    link: "https://forms.gle/GKzwJkK6yGEpCrCRA",
    buttonText: "Indie Games Intake",
    color: "#F04924",
        isOpen: false

  },
];
